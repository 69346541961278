const firebaseConfig = {
  apiKey: 'AIzaSyDkbcohsYOrw5LH8SM324fxnH24igF8QEI',
  authDomain: 'eventually-241912.firebaseapp.com',
  projectId: 'eventually-241912',
  storageBucket: 'eventually-241912.appspot.com',
  messagingSenderId: '188444293301',
  appId: '1:188444293301:web:c42849b4d9a0aae45de6fc',
};

const apiUrl = (() => {
  try {
    if (window.location.host.includes('localhost')) {
      return 'http://localhost:9773';
    }
  } catch {
    // ignore
  }
  return 'https://europe-west6-eventually-241912.cloudfunctions.net/com-priips-documents';
})();

const config = {
  firebase: firebaseConfig,
  apiUrl,
};
export default config;
