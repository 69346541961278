import React, { useCallback } from 'react';

import { getAuth, signOut } from 'firebase/auth';

export default function Header() {
  const auth = getAuth();

  const onSignOut = useCallback(() => {
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        alert(err);
      });
  }, [auth]);

  return (
    <nav className=" bg-white p-6">
      <div className="flex items-center justify-between flex-wrap">
        <div className="flex items-center flex-shrink-0 text-black mr-6">
          <span className="font-semibold text-xl tracking-tight">
            PRIIP Document Uploader
          </span>
        </div>
        <div className="w-auto">
          <div className="text-sm lg:flex-grow"></div>
          <div>
            <button
              className="inline-block text-sm px-4 py-2 leading-none border rounded text-gray-dark border-gray-dark hover:border-black hover:text-white hover:bg-black mt-0 transition ease-in-out duration-150"
              onClick={onSignOut}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
