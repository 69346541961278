import MissingProductList from './components/MissingProductList';
import React from 'react';

export default function ProductErrorsGrid({ missingDocuments }) {
  if (missingDocuments.length === 0) {
    return <React.Fragment />;
  }

  return (
    <div className="w-full mb-10">
      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
        {missingDocuments?.map((prodItr) => (
          <MissingProductList
            key={prodItr?.id}
            productName={prodItr?.id}
            productData={prodItr?.missingDocuments}
          />
        ))}
      </ul>
    </div>
  );
}
