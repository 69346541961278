import React from 'react';

import ResultCard from '../ResultCard';

export default function ProductSuccessGrid({ products }) {
  if (products?.length === 0) {
    return <React.Fragment />;
  }

  return (
    <div className="w-full mb-10">
      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
        {products?.map((productItr, idx) => {
          return <ResultCard key={idx} productName={productItr.id} />;
        })}
      </ul>
    </div>
  );
}
