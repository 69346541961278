import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import './utils/firebase';

import Header from './components/Header';
import Login from './components/Login';
import ProductErrorsGrid from './components/ProductErrorsGrid';
import ProductSuccessGrid from './components/ProductSuccessGrid';
import Spinner from './components/Spinner';
import Uploader from './components/Uploader';

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [parsedProducts, setParsedProducts] = useState([]);
  const [missingDocuments, setMissingDocuments] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <div className="w-full h-full fixed top-0 left-0 bg-pampas opacity-75 z-50 flex flex-1 justify-center items-center">
        <Spinner />
      </div>
    );
  }

  if (!currentUser) {
    return <Login />;
  }

  //list of products that have all require documents
  const successProductsList = parsedProducts?.filter(
    (productItr) => productItr.isComplete,
  );

  return (
    <>
      <Header />
      <div className="px-6">
        <Uploader
          currentUser={currentUser}
          setParsedProducts={setParsedProducts}
          setMissingDocuments={setMissingDocuments}
        />
        {(missingDocuments?.length > 0 || successProductsList?.length > 0) && (
          <p className="font-bold mb-5">Imported Products</p>
        )}
        {missingDocuments?.length > 0 && (
          <ProductErrorsGrid missingDocuments={missingDocuments} />
        )}
        <ProductSuccessGrid products={successProductsList} />
      </div>
    </>
  );
}
