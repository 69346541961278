export const firebaseAuthErrorMessages = {
  'auth/wrong-password':
    'Please check the password you have entered or contact support (it@headbits.com).',
  'auth/user-not-found':
    'The EVA account was not found. Please check the email address you entered or contact support (it@headbits.com).',
};

export function translateFirebaseErrorCode(errorCode) {
  if (firebaseAuthErrorMessages[errorCode]) {
    return firebaseAuthErrorMessages[errorCode];
  }
  return `Unknown server error. Please contact the support (it@headbits.com). Error Code: ${errorCode}`;
}
