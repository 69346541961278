import React, { useReducer, useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import Dropzone from 'react-dropzone';
import axios from 'axios';

import config from '../../config';

const PROGRESS_STATES = {
  COMPLETED: 'completed',
  ERROR: 'error',
  UPLOAD: 'upload',
};

const initialState = {
  progress: null,
  message: '',
  selectedFile: undefined,
  forceUpdate: false,
  dryRun: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PROGRESS':
      return {
        ...state,
        progress: action.progress,
      };
    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        message: action.message,
      };
    case 'SET_SELECTED_FILE':
      return {
        ...initialState,
        forceUpdate: state.forceUpdate,
        dryRun: state.dryRun,
        selectedFile: action.selectedFile,
      };
    case 'RESET_SELECTED_FILE':
      return {
        ...state,
        selectedFile: null,
      };
    case 'REMOVE_SELECTED_FILE':
      return {
        ...state,
        selectedFile: null,
      };
    case 'SET_FORCE_UPDATE':
      return {
        ...state,
        forceUpdate: action.forceUpdate,
      };
    case 'SET_DRY_RUN':
      const safeForceUpdate = action.dryRun ? false : state.forceUpdate;

      return {
        ...state,
        forceUpdate: safeForceUpdate,
        dryRun: action.dryRun,
      };
    default:
      throw new Error();
  }
}

const auth = getAuth();

export default function Uploader({ setParsedProducts, setMissingDocuments }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedFile, progress } = state;

  const uploadFile = useCallback(
    async (file, forceUpdate, dryRun, onUploadProgress) => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        let apiUrl = config.apiUrl;
        if (forceUpdate) {
          apiUrl = `${config.apiUrl}?forceUpdate=${forceUpdate}`;
        }
        if (dryRun) {
          apiUrl = `${config.apiUrl}?dryRun=${dryRun}`;
        }

        const jwt = await auth.currentUser.getIdToken();
        const res = await axios.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${jwt}`,
          },
          onUploadProgress,
        });

        if (res?.data?.productsWithMissingDocs) {
          setMissingDocuments(res.data.productsWithMissingDocs);
        }
        setParsedProducts(res.data.result.products);

        dispatch({
          type: 'SET_PROGRESS',
          progress: PROGRESS_STATES.COMPLETED,
        });
        dispatch({
          type: 'RESET_SELECTED_FILE',
        });
      } catch (err) {
        dispatch({
          type: 'SET_PROGRESS',
          progress: PROGRESS_STATES.ERROR,
        });
        dispatch({
          type: 'SET_ERROR_MESSAGE',
          message: err?.response?.data?.message || err.message,
        });

        if (err?.response?.data?.productsWithMissingDocs) {
          setMissingDocuments(err?.response?.data?.productsWithMissingDocs);
          setParsedProducts(err?.response?.data?.products);
        }
      }
    },
    [dispatch, setParsedProducts, setMissingDocuments],
  );

  const onDrop = useCallback(
    (files) => {
      if (files.length > 0) {
        dispatch({
          type: 'SET_SELECTED_FILE',
          selectedFile: files[0],
        });
      }
      setMissingDocuments([]);
      setParsedProducts([]);
    },
    [dispatch, setMissingDocuments, setParsedProducts],
  );

  const upload = useCallback(() => {
    dispatch({
      type: 'SET_PROGRESS',
      progress: PROGRESS_STATES.UPLOAD,
    });

    setMissingDocuments([]);
    setParsedProducts([]);
    uploadFile(selectedFile, state.forceUpdate, state.dryRun, () => {});
  }, [
    dispatch,
    state,
    selectedFile,
    uploadFile,
    setMissingDocuments,
    setParsedProducts,
  ]);

  const isUploading = progress === PROGRESS_STATES.UPLOAD;

  const removeSelectedFile = () => {
    dispatch({ type: 'REMOVE_SELECTED_FILE' });
  };

  return (
    <>
      <div className="flex flex-col justify-center py-10 h-full">
        <div className="w-auto h-auto bg-white p-10 shadow-sm">
          <Dropzone
            onDrop={onDrop}
            multiple={false}
            accept={{ 'application/vnd.ms-excel': ['.xls', '.xlsx'] }}
            disabled={isUploading}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="border-dashed border-2 border-gray-200 rounded-lg w-auto h-auto">
                  <div className="text-center p-10">
                    <p>Drag'n'drop file upload or click to select</p>
                    <p className="text-xs text-gray-400 pt-2">
                      Supported file formats: .xlsx, .xls
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="mt-4 mb-6">
            <div className="mt-4 space-y-4">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="dry-run"
                    name="dryRun"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={state.dryRun}
                    onChange={(e) =>
                      dispatch({
                        type: 'SET_DRY_RUN',
                        dryRun: e.target.checked,
                      })
                    }
                  />
                </div>
                <div className="text-sm leading-6">
                  <label
                    htmlFor="dry-run"
                    className="font-medium text-gray-900"
                  >
                    Dry Run
                  </label>
                  <p className="text-gray-500">
                    Only validate the file without updating the database.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 space-y-4">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="force-update"
                    name="forceUpdate"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:opacity-20 disabled:cursor-not-allowed"
                    disabled={state.dryRun}
                    checked={state.forceUpdate}
                    onChange={(e) =>
                      dispatch({
                        type: 'SET_FORCE_UPDATE',
                        forceUpdate: e.target.checked,
                      })
                    }
                  />
                </div>
                <div className="text-sm leading-6">
                  <label
                    htmlFor="force-update"
                    className="font-medium text-gray-900"
                  >
                    Force Update
                  </label>
                  <p className="text-gray-500">
                    Disable the integrity check and force the upload and update
                    of the file.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center space-between place-content-start mt-4">
            <div className="w-auto">
              <button
                type="button"
                className="inline-flex items-center px-6 py-2 font-normal leading-6 text-md shadow text-white border-sl-red bg-sl-red hover:bg-red-dark disabled:bg-gray-light transition ease-in-out duration-150"
                onClick={upload}
                disabled={!selectedFile?.name || isUploading}
              >
                {isUploading && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {isUploading ? 'Uploading...' : 'Upload'}
              </button>
            </div>
            {selectedFile?.name && (
              <>
                <div className="w-auto ml-6">
                  <div className="text-xs leading-5 font-semibold bg-gray-400/10 rounded-full py-1 px-3 flex items-center space-x-2 hover:bg-gray-400/20">
                    {selectedFile?.name}
                  </div>
                </div>
                <button
                  onClick={removeSelectedFile}
                  className="text-gray-500 bg-gray-200 rounded-full w-6 h-6 flex items-center justify-center ml-2 hover:bg-gray-500 hover:text-white"
                >
                  <span className="text-xs font-bold">X</span>
                </button>
              </>
            )}
          </div>
          {progress === PROGRESS_STATES.COMPLETED && (
            <div
              className="mt-5 bg-green-100 border border-green-400 text-green-700 bg- px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block">
                The PRIIPs were successfully published.
              </span>
            </div>
          )}
          {progress === PROGRESS_STATES.ERROR && (
            <>
              <div
                className="mt-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block">{state.message}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
