import React, { useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';

export default function ResultCard({ productName, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="overflow-hidden rounded-xl border border-gray-200 shadow-sm">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <div className="text-sm font-medium leading-6 text-gray-900">
          {productName}
        </div>
      </div>
      {!children && (
        <div className="flex justify-between gap-x-4 py-3 p-6 bg-white">
          <dt className="text-gray-500">Status</dt>
          <dd className="flex items-start gap-x-2">
            <div className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">
              Success
            </div>
          </dd>
        </div>
      )}
      {children && (
        <>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6 p-6  bg-white">
            <div className="flex justify-between gap-x-4 py-3 ">
              <dt className="text-gray-500">Status</dt>
              <dd className="flex items-start gap-x-2">
                <div className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/10">
                  Failure
                </div>
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">See details</dt>
              <button className="flex" onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? (
                  <ChevronDownIcon className="h-5 w-5" />
                ) : (
                  <ChevronRightIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </dl>
          {isOpen && <div className="p-6 bg-white">{children}</div>}
        </>
      )}
    </div>
  );
}
