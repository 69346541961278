import React, { useState, useCallback } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  inMemoryPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { useForm } from 'react-hook-form';

import Modal from '../Modal';
import Spinner from '../Spinner';

import headbitsLogo from '../../assets/headbits.png';
import { translateFirebaseErrorCode } from './firebaseErrorMessages';

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginErrorOpen, setIsLoginErrorOpen] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  const { register, handleSubmit } = useForm({
    defaultValues: {
      rememberMe: true,
    },
  });

  const onSignIn = useCallback(
    (formData) => {
      const auth = getAuth();

      setIsLoading(true);

      // check if the login should be saved
      const persistence = formData?.rememberMe
        ? browserSessionPersistence
        : inMemoryPersistence;

      setPersistence(auth, persistence)
        .then(() => {
          return signInWithEmailAndPassword(
            auth,
            formData?.email,
            formData?.password,
          );
        })
        .catch((err) => {
          setLoginErrorMessage(translateFirebaseErrorCode(err.code));
          setIsLoginErrorOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [setLoginErrorMessage, setIsLoginErrorOpen],
  );

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={headbitsLogo}
                alt="Workflow"
              />
            </div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Log in with the EVA account
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form
                className="space-y-6"
                action="#"
                method="POST"
                onSubmit={handleSubmit(onSignIn)}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-Mail address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-headbits-500 focus:border-headbits-500 sm:text-sm"
                      placeholder="E-Mail Adresse"
                      {...register('email', { required: true })}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-headbits-500 focus:border-headbits-500 sm:text-sm"
                      placeholder="Passwort"
                      {...register('password', { required: true })}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-headbits-900 focus:ring-headbits-500 border-gray-300 rounded transition-all"
                      {...register('rememberMe')}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Stay logged in
                    </label>
                  </div>

                  <div className="text-sm">
                    <a
                      href="mailto:it@headbits.com?subject=EVA Password Forgotten"
                      className="font-medium text-headbits-600 hover:text-headbits-500 transition-colors focus:outline-headbits-500"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-headbits-900 hover:bg-headbits-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-headbits-500 transition-colors"
                  >
                    {isLoading && <Spinner />}
                    Log in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Login failed"
        body={loginErrorMessage}
        isOpen={isLoginErrorOpen}
        setIsOpen={setIsLoginErrorOpen}
      />
    </>
  );
}
