import React, { useState } from 'react';
import ResultCard from '../../../ResultCard';

export default function MissingProductList({ productName, productData }) {
  const [isExtended, setIsExtended] = useState(false);

  const resultObj = productData.reduce((acc, obj) => {
    acc[obj.type] = acc[obj.type] || [];
    acc[obj.type].push(obj.filePath);
    return acc;
  }, {});

  console.log('resultObj', resultObj['KID']);

  const listElementCLasses = 'text-xs font-medium leading-6 text-gray-900';
  const listTitleCLasses =
    'font-bold mb-2 text-sm leading-6 underline underline-offset-2';

  const sidSection = resultObj['SID']?.map((item, index) => {
    // console.log('item', item);

    return (
      <li key={index} className={listElementCLasses}>
        {item}
      </li>
    );
  });

  const missingDocumentsNames = (
    <>
      {resultObj['KID']?.length > 0 && (
        <>
          <p className={listTitleCLasses}>KID Documents:</p>
          <ol className="list-decimal list-inside">
            {resultObj['KID']?.map((item, index) => (
              <li key={index} className={listElementCLasses}>
                {item}
              </li>
            ))}
          </ol>
        </>
      )}
      {resultObj['PP']?.length > 0 && (
        <>
          <p className={listTitleCLasses}>PP Documents:</p>
          <ol className="list-decimal list-inside">
            {resultObj['PP'].map((item, index) => (
              <li key={index} className={listElementCLasses}>
                {item}
              </li>
            ))}
          </ol>
        </>
      )}
      {resultObj['SID']?.length > 0 && (
        <>
          <p className={listTitleCLasses}>
            SID Documents ({resultObj['SID']?.length}):
          </p>
          <ol className="list-decimal list-inside">
            {isExtended ? sidSection : sidSection.slice(0, 3)}
          </ol>
          <button
            type="button"
            className="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-gray-600 shadow-sm hover:bg-indigo-200 mt-2"
            onClick={() => setIsExtended(!isExtended)}
          >
            {isExtended ? 'Hide' : 'Show all'}
          </button>
        </>
      )}
    </>
  );

  return (
    <ResultCard productName={productName} children={missingDocumentsNames} />
  );
}
